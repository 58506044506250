@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.StartMashie {
  align-items: center;
  display: flex;
  justify-content: center;

  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 90%;
    justify-content: space-between;

    @include breakpoint(xs) {
      height: 80%;
    }

    @include breakpoint(sm) {
      height: 70%;
      width: 400px
    }

    @include breakpoint(md) {
      width: 600px;
    }

    @include breakpoint(xxxl) {
      width: 700px;
    }

    h2 {
      font-size: $title;
      letter-spacing: .1rem;
      margin: 0;

      @include breakpoint(md) {
        font-size: $title-desktop;
      }
    }

    .logo-mashie {
      width: 60%;

      @include breakpoint(md) {
        width: 352px;
      }
    }

    article {
      p {
        margin: 15px 0;
        text-align: center;
      }
    }
  }
}
