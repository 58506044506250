@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Mashie {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  progress {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    height: 10px;
    position: fixed;
    top: 0;
    vertical-align: top;
    width: 100%;
    z-index: 2;

    &::-webkit-progress-bar {
      background-color: $white;
    }

    &::-webkit-progress-value {
      background-color: var(--primary-color);
    }

    &[value] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      color: var(--primary-color);
    }
  }

  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    @include breakpoint(xs) {
      height: 80%;
    }

    @include breakpoint(sm) {
      height: 70%;
      width: 400px
    }

    @include breakpoint(md) {
      width: 663px;
    }

    @include breakpoint(lg) {
      width: 863px;
    }

    @include breakpoint(xxxl) {
      width: 963px;
    }

    .mashie-info {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      margin: 30px 0;

      p {
        margin-top: 20px;
        text-align: left;
      }
    }

    .comparisons {
      display: flex;
      flex-direction: column;
      height: 70%;
      margin-top: 20px;
      width: 100%;

      @include breakpoint(md) {
        margin-top: 30px;
      }

      @include breakpoint(lg) {
        width: max-content;
      }
    }

    .error-message {
      color: var(--primary-color);
      font-size: $reading-desktop;
      margin-top: 20px;
      opacity: 0;
      text-align: center;

      &.active{
        animation: blinker;
        animation-delay: .5s;
        animation-duration: 1s;
        animation-iteration-count: 2;
        opacity: 1;
      }

      @keyframes blinker {
        0% {
          background-color: var(--primary-color);
        }

        100% {
          background-color: transparent;
        }
      }
    }
  }
}
