@mixin font-face($font-family, $font-suffix, $font-weight, $font-style) {
  @font-face {
    font-family: $font-family;
    src: url($font-suffix) format("truetype"),
    url($font-suffix) format("opentype");
    font-weight: $font-weight;
    font-style: $font-style;
  }
}

@include font-face(
    ChackraPetch,
    "../fonts/ChakraPetch-Bold.ttf",
    500,
    normal
);

@include font-face(
    ChackraPetch,
    "../fonts/ChakraPetch-Regular.ttf",
    400,
    normal
);

@include font-face(
    ChackraPetch,
    "../fonts/ChakraPetch-Light.ttf",
    200,
    normal
);
