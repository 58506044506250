@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.AfterScreens {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding: 100px 25px 0 0;

  progress {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    height: 10px;
    position: fixed;
    top: 0;
    vertical-align: top;
    width: 100%;
    z-index: 2;

    &::-webkit-progress-bar {
      background-color: transparent;
    }

    &::-webkit-progress-value {
      background-color: var(--primary-color);
    }

    &[value] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      color: var(--primary-color);
    }
  }

  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 90%;
    justify-content: space-between;
    margin-left: 25px;
    width: 100%;

    .logo-mashie {
      width: 60%;

      @include breakpoint(md) {
        width: 352px;
      }
    }

    @include breakpoint(lg) {
      width: 65%;
    }

    @include breakpoint(md) {
      margin-left: 100px;
    }

    video {
      @include breakpoint(sm) {
        margin-top: 38px;
      }

      @include breakpoint(lg) {
        margin-top: 80px;
      }
    }

    h2 {
      font-size: $title;
      letter-spacing: .1rem;
      margin: 20px 0;
      width: 100%;

      @include breakpoint(md) {
        font-size: $title-desktop;
        text-align: left;
      }
    }

    .logo-mashie {
      width: 60%;

      @include breakpoint(md) {
        width: 352px;
      }
    }

    span {
      color: var(--primary-color);
      font-size: $title;
      font-size: 30px;
      font-weight: 400;
      letter-spacing: .1rem;
      margin: 0;

      @include breakpoint(md) {
        font-size: 43px;
      }
    }

    p {
      font-size: 18px;
      font-weight: 300;
      margin: 30px;
      text-align: center;
      width: 100%;
      
      @include breakpoint(lg) {
        font-size: 26px;
      }
    }

    .description {
      font-weight: 300;
      font-size: 17px;
      text-align: center;
      width: 100%;

      @include breakpoint(sm) {
        font-size: 22px;
        margin-top: 38px;
      }

      @include breakpoint(md) {
        font-size: $sub-title-desktop;
      }

      @include breakpoint(lg) {
        margin-top: 100px;
        font-size: 26px;
      }

      br {
        content: "";
        display: block;
        flex-direction: column;
        margin-top: 30px;
      }
    }

    .Input {
      align-items: flex-start;
      margin-top: 80px;

      label {
        font-size: 18px;

        @include breakpoint(sm) {
          font-size: 22px;
          text-align: left;
        }

        @include breakpoint(md) {
          font-size: 24px;
        }

        @include breakpoint(lg) {
          font-size: $label-desktop;
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;

      @include breakpoint(lg) {
        margin-top: 36px;
      }
    }
  }
}

.logo-footer {
  bottom: 10%;
  height: 70px;
  position: fixed;
  right: 10%;
}
