@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Results {
  align-items: center;
  display: flex;
  justify-content: center;

  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    @include breakpoint(xs) {
      min-height: 100vh;
      width: 65%;
    }

    .mashie-info {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      p {
        margin-top: 20px;
      }
    }

    .sentences {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 20px;
      height: 70%;
      margin-top: 20px;
      width: 100%;

      @include breakpoint(md) {
        gap: 30px;
        margin-top: 30px;
      }

      @media (screen) and (min-color-index: 0) and(-webkit-min-device-pixel-ratio: 0) {
        gap: unset;

        .Sentence {
          margin: 0 0 20px;

          &:last-child {
            margin: unset;
          }
        }
      }

      .Sentence {
        height: 120px;
        pointer-events: none;

        @include breakpoint(xxxl) {
          height: max-content;
        }
      }
    }
  }
}
