body {
  margin: 0;
}

:root {
  --primary-color: #b40909;
}

html {
  scroll-behavior: smooth;
}
