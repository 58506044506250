@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.MashieCode {
  background-color: rgba(0, 0, 0, .6);
  height: 100%;

  &:before {
    background-image: url("../../../assets/images/background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 90%;
    justify-content: flex-start;
    padding: 60px 0;

    @include breakpoint(xs) {
      height: 80%;
    }

    @include breakpoint(md) {
      height: 70%;
    }

    @include breakpoint(lg) {
      justify-content: space-between;
    }

    h2 {
      font-size: $title;
      letter-spacing: .1rem;
      margin: 0;

      @include breakpoint(md) {
        font-size: $title-desktop;
      }
    }

    form {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      width: 100%;

      @include breakpoint(md) {
        width: 400px;
      }

      @include breakpoint(lg) {
        width: 600px;
      }

      @include breakpoint(xxxl) {
        width: 700px;
      }

      .Input {
        input {
          font-weight: bold;
        }
      }
    }
  }
}
