@import "../../../assets/scss/mixins";

.Comparison {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  &:first-child {
    margin-top: unset;
  }

  @include breakpoint(md){
    flex-direction: row;
  }

  @include breakpoint(lg){
    margin-top: 30px;
  }

  &.inactive {
    opacity: .6;
    pointer-events: none;
  }

  p {
    margin: 5px;

    @include breakpoint(md) {
      margin: 0 20px;
    }
  }
}
