@import "../assets/scss/fonts.scss";
@import "../assets/scss/variables.scss";
@import "../assets/scss/mixins.scss";

.App {
  & > * {
    background-image: url("../assets/images/background-dots.png");
    padding: 20px;
  }

  * {
    box-sizing: border-box;
    color: $white;
    font-family: ChackraPetch, sans-serif;
    font-size: $reading;
    margin: 0;

    @include breakpoint(md) {
      font-size: $reading-desktop;
    }
  }
}
