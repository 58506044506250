@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Sentence {
  align-items: center;
  border: 1px solid var(--primary-color);
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  min-height: 94px;
  padding: 20px 5px;
  width: 100%;

  @include breakpoint(sm) {
    min-width: 80%;
    width: 80%;
  }

  @include breakpoint(md) {
    min-height: 120px;
    min-width: 300px;
    padding: 5px;
    width: 300px;
  }

  @include breakpoint(lg) {
    min-width: 400px;
    padding: 0 30px;
    width: 400px;
  }

  &:hover {
    filter: brightness(0.7);
    background-color: var(--primary-color);
  }

  &.selected {
    background-color: var(--primary-color);
  }

  p {
    width: max-content;
  }
}
