@import "../../../assets/scss/variables";

.Button {
  background-color: var(--primary-color);
  border-radius: 54px;
  border: 2px solid var(--primary-color);
  color: black;
  cursor: pointer;
  font-size: $sub-title;
  font-weight: 600;
  margin: 30px 0;
  padding: 20px 100px;
  text-transform: uppercase;
  transition: filter 0.2s;
  width: max-content;

  &:hover {
    filter: brightness(0.8);
  }

  &.outline {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid var(--primary-color);
  }
}
