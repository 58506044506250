@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Introduction {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;

  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 90%;
    justify-content: space-between;
    margin-left: 25px;
    width: 100%;

    @include breakpoint(sm) {
      height: 60%;
      width: 400px;
    }

    @include breakpoint(md) {
      width: 60%;
    }

    h2 {
      font-size: $title;
      letter-spacing: .1rem;
      margin: 0;

      @include breakpoint(md) {
        font-size: $title-desktop;
      }
    }

    span {
      color: var(--primary-color);
      font-size: $title;
      font-size: 30px;
      font-weight: 400;
      letter-spacing: .1rem;
      margin: 0;

      @include breakpoint(md) {
        font-size: 50px;
      }
    }

    .logo-mashie {
      width: 60%;

      @include breakpoint(md) {
        width: 352px;
      }
    }

    p {
      font-size: 18px;
      font-weight: 300;
      margin: 30px;
      text-align: center;
      width: 100%;
      
      @include breakpoint(lg) {
        font-size: 26px;
      }
    }
  }
}
