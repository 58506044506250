// $primary-color: #b40909; // Original
// $primary-color-hover: #8d0909; // Original
$primary-color: #62DE09;  // Temporary
$primary-color-hover: #59BC0C; // Temporary

$white: #ffffff;

$reading: 1rem;
$reading-desktop: 1.3rem;
$sub-title: 1.5rem;
$sub-title-desktop: 1.75rem;
$label-desktop: 2.25rem;
$title: 1.8rem;
$title-desktop: 2.18rem;

$breakpoints: (
  "xs": 400px,
  "sm": 576px,
  "md": 768px,
  "lg": 992px,
  "xl": 1200px,
  "xl+": 1400px,
  "xxl": 1600px,
  "xxxl": 1800px
);
