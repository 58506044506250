@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Radio {
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-size: 33px;
  justify-content: flex-start;
  padding: 12px 0;
  width: 100%;
  
  @include breakpoint(lg) {
    font-size: 2.25rem;
    padding: 20px 0;
  }

  &.selected {
    // margin-left: -15px;
  }

  &.selected, &:hover {
    background-color: transparent;
  }

  div {
    align-items: center;
    display: flex;

    p {
      display: block;
      font-size: 23px;
      // font-weight: 700;
      letter-spacing: 2px;
      margin: 0;
    }

    .radio {
      align-items: flex-end;
      display: flex;
      justify-content: flex-start;
      margin-right: 15px;
      width: 19px;
      height: 33px;

      @include breakpoint(lg) {
        width: unset;
      }

      .control {
        background-color: transparent;
        border: 3px solid var(--primary-color);
        border-radius: 50%;
        display: flex;
        height: 33px;
        width: 33px;
      }

      input {
        height: 0;
        margin: 0;
        opacity: 0;
        width: 0;
        
        @include breakpoint(lg) {
          // margin: 0 15px 0 0;
        }
      }

      input + .control::before {
        background-color: var(--primary-color);
        border-radius: 30%;
        box-shadow: inset 0.5em 0.5em currentColor;
        color: var(--primary-color);
        content: "";
        height: 27px;
        transform: scale(0);
        transition: 100ms transform ease-in-out;
        width: 39px;
      }

      input:checked + .control::before {
        transform: scale(1);
      }
    }
  }

  p {
    font-size: $title-desktop;
  }

  .label {
    margin: 30px 0 50px 0;
    color: var(--primary-color);
  }
}