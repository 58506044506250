@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Select {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  position: relative;

  & > p {
    color: var(--primary-color);
    font-size: 0.7rem;
    margin: 0 0 5px 0;
    opacity: 0;

    &.visible {
      opacity: 1;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;

    & > label {
      color: $white;
      font-size: $reading;
      font-weight: 500;
      margin-bottom: 20px;
      text-align: left;

      @include breakpoint(md) {
        font-size: $label-desktop;
      }
    }

    & > select {
      background-color: transparent;
      border: 2px solid var(--primary-color);
      border-radius: unset;
      color: var(--primary-color);
      cursor: pointer;
      font-size: $reading;
      font-weight: 500;
      height: 48px;
      outline: 2px solid $white;
      padding: 0 25px 0 5px;
      -webkit-appearance: none;

      @include breakpoint(md) {
        font-size: $reading-desktop;
      }

      & > option {
        color: var(--primary-color);
        cursor: pointer;

        &:hover {
          color: $white;
        }
      }
    }

    svg {
      bottom: 12px;
      fill: var(--primary-color);
      height: 20px;
      position: absolute;
      right: 7px;
      width: 20px;
    }
  }
}
