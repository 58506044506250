@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Input {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    color: var(--primary-color);
    font-size: $sub-title;
    margin-bottom: 10px;

    @include breakpoint(md) {
      font-size: $sub-title-desktop;
    }
  }

  p {
    color: var(--primary-color);
    height: 21px;
    opacity: 0;

    &.displayError {
      opacity: 1;
    }
  }

  input {
    background-color: transparent;
    border: 2px solid var(--primary-color);
    border-radius: 0;
    box-sizing: border-box;
    color: var(--primary-color);
    font-size: $sub-title;
    font-weight: 200;
    height: 70px;
    width: 100%;

    &:focus {
      outline: 1px solid $white;
    }
  }

  input[type=number] {
    appearance: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
